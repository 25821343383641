export const GET_TOKEN_ENDPOINT = process.env.NEXT_PUBLIC_TOKEN_URL;
export const SITE = process.env.NEXT_PUBLIC_SITE;
export const SITE_NAME = process.env.NEXT_PUBLIC_SITE_NAME;
export const SITE_URL = process.env.NEXT_PUBLIC_SITE_URL;
export const SITE_CONTACT = process.env.NEXT_PUBLIC_SITE_CONTACT;
export const SITE_PRIMARY_COLOR = process.env.NEXT_PUBLIC_SITE_PRIMARY_COLOR;
export const SITE_SECONDARY_COLOR = process.env.NEXT_PUBLIC_SITE_SECONDARY_COLOR;
export const GRAPHQL_ENDPOINT = process.env.NEXT_PUBLIC_BACKEND_URL;
export const LOGIN_URL = process.env.NEXT_PUBLIC_LOGIN_URL;
export const PAYFLOW_URL = process.env.NEXT_PUBLIC_PAYFLOW_URL;
export const AWS_URL = process.env.NEXT_PUBLIC_AWS_URL;
export const API_URL = process.env.NEXT_PUBLIC_API_URL;
