import { SITE_NAME, SITE_URL } from "@api/constants";
import "@assets/main.css";
import { appWithTranslation } from "next-i18next";
import { DefaultSeo } from "next-seo";
import type { AppProps } from "next/app";
import { FC, PropsWithChildren } from "react";

import Head from "@components/Head";
import { UserProvider } from "@components/UserContext";

const Noop: FC<PropsWithChildren> = ({ children }) => <>{children}</>;

function MyApp({ Component, pageProps }: AppProps) {
  const Layout = (Component as any).Layout || Noop;

  return (
    <>
      <Head />
      <DefaultSeo
        openGraph={{
          type: "website",
          url: SITE_URL,
          images: [
            {
              url: `${SITE_URL}/static/preview.png`,
              width: 1080,
              height: 600,
              alt: SITE_NAME,
            },
          ],
        }}
        additionalLinkTags={[
          {
            rel: "icon",
            type: "image/x-icon",
            sizes: "64x66",
            href: "/static/favicon.ico",
          },
        ]}
        additionalMetaTags={[
          {
            name: "google-site-verification",
            content: "RijVgdOqMV-oHsjMh2KG1Uhw3SO7ddLNLVSCoQiA5nk",
          },
        ]}
      />
      <UserProvider>
        <Layout pageProps={pageProps}>
          <Component {...pageProps} />
        </Layout>
      </UserProvider>
    </>
  );
}

export default appWithTranslation(MyApp);
